<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card
          :title="$t('Edit New Galleryharem')"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.img"
                  class="mt-2"
                  :state="errors.img ?false:null"
                  :placeholder="$t('img')"
                />
                <small
                  v-if="errors.img"
                  class="text-danger"
                >{{ errors.img[0] }}</small>
                <label>{{ $t('img') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.part"
                  class="mt-2"
                  :state="errors.part ?false:null"
                  :placeholder="$t('part')"
                />
                <small
                  v-if="errors.part"
                  class="text-danger"
                >{{ errors.part[0] }}</small>
                <label>{{ $t('part') }}</label>
              </div>
            </b-col>
          </b-row>
        </b-card>>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-card>
          <b-row>
            <b-col
              cols="12"
            >
              <b-button
                class="w-100 mb-1"
                variant="gradient-success"
                @click="update"
              >
                {{ $t('Update') }}
              </b-button>
              <b-button
                class="w-100"
                variant="gradient-danger"
                @click="data={},errors=[]"
              >
                {{ $t('Reset Form') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      errors: [],
      fileErrors: '',

    }
  },
  computed: {

    Element() { return this.$store.getters['galleryharem/GetElement'] },
  },
  created() {
    this.getData()
  },
  methods: {
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    getData() {
      this.$store.dispatch('galleryharem/GetElement', this.$route.params.galleryharem).then(() => {
        this.data = this.Element
      })
    },
    update() {
      this.errors = []
      this.$store.dispatch('galleryharem/Update', this.data).then(() => {
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
